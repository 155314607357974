.sortableItem {
    position: relative;
    cursor: grab;
}
.sortableItem::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
