.rc-collapse {
    background: transparent !important;
    border: none !important;
}
.rc-collapse-item {
    border-top: none !important;
    border-bottom: 1px solid #4b2f3e25 !important;
}

.rc-collapse-header {
    color: #4b2f3e !important;
    font-weight: 600;
    background-color: transparent !important;
    padding: 10px 0 !important;
}

.rc-collapse-header:focus {
    outline: none !important;
}

.rc-collapse-content {
    color: #4b2f3e !important;
    background: transparent !important;
}

.rc-collapse-content-box {
    margin-top: 5px !important;
}

.rc-collapse-extra {
    margin-right: 0 !important;
}
